<template>
    <div id="app">
        <van-nav-bar
            title="我的账单"
            left-text="关闭"
            left-arrow
            @click-left="cancelUrl"/>
        <van-tabs v-model="active" :before-change="tabBeforChange">
            <van-tab title="历史账单">
                <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                    <div>
                        <div v-for="(item,index) in keys" :key="index" >
                            <div class="date_title">
                                {{item}}
                                <br/>
                                <!-- <label>支出：￥1000 收入￥2000</label> -->
                            </div>       
                            <div class="price_body" v-for="(t,i) in tableData[item]" :key="i">
                                <div style="display:inline-block;width:85%">{{t.description}}</div>
                                <div style="display:inline-block;width:10%;font-weight:600">
                                    {{t.totalFee}}
                                </div>
                                <div class="body_date">{{t.createTime}}</div>
                            </div>
                        </div>
                    </div>
                    <van-empty v-if="tableData.length!=0" description="下拉刷新当前页面"></van-empty>
                    <van-empty image="search" v-if="tableData.length==0" description="没有更多了" />
                </van-pull-refresh>
            </van-tab>
            <van-tab title="待结算订单">
                    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                        <div class="price_history" v-if="!isScrap">
                            <van-card
                                v-for="(item,index) in historyTableData"
                                :key="index"
                                :price="parseFloat(item.totalAmount).toFixed(2)">
                                <template #title>
                                    <div style="font-size:18px;">{{item.licensePlate}}</div>
                                </template>
                                <template #tags>
                                    系统估价：<label>{{item.scrapPrice}}</label>
                                    成交价：<label>{{item.customerPrice}}</label>
                                    <br>                                
                                    后台估价：<label>{{item.originalScrapPrice}}</label>
                                    后台确认价：<label>{{item.originalCustomPrice}}</label>
                                </template>
                                <template #footer>
                                    <van-button @click="$router.replace({path:'/morderdetail',query:{id:item.orderId,go_url:'/userBill'}});" >详情</van-button>
                                </template>
                            </van-card>
                        </div>
                        <div class="price_history" v-else>
                             <van-card
                                v-for="(item,index) in historyTableData"
                                :price="parseFloat(item.customerPrice||0).toFixed(2)"
                                :key="index">
                                <template #title>
                                    <div style="font-size:18px;">{{item.licensePlate}}</div>
                                </template>
                                <template #tags>
                                    系统估价：<label>{{item.scrapPrice}}</label>
                                </template>
                                <template #footer>
                                    <van-button @click="$router.replace({path:'/morderdetail',query:{id:item.orderId,go_url:'/userBill'}});" >详情</van-button>
                                </template>
                            </van-card>
                        </div>
                        <van-empty image="search" v-if="tableData.length==0" description="没有更多了" />
                    </van-pull-refresh>
            </van-tab>
        </van-tabs>
    </div>
</template>
<script>
import { Button,Tag,PullRefresh,Card,ActionSheet,Empty,CellGroup,Cell,Field,Tabs,Tab,NavBar } from 'vant';
import {userBillList,commissionList,} from '../../api/common'
import {getSelfList} from '../../api/personal'
import {allinpayUser} from '../../store/allinpay'
    export default({
        name:'myorder',
        components:{
            [Button.name]: Button,
            [Tag.name]: Tag,
            [PullRefresh.name]: PullRefresh,
            [Card.name]: Card,
            [ActionSheet.name]: ActionSheet,
            [Empty.name]: Empty,
            [CellGroup.name]: CellGroup,
            [Cell.name]: Cell,
            [Field.name]: Field,
            [Tabs.name]: Tabs,
            [Tab.name]: Tab,
            [NavBar.name]: NavBar,
        },
        data() {
            return {
                isScrap:false,
                active:0,
                isInit:false,
                isHostoryInit:false,
                actions: [],
                isLoading:false,
                date_show:false,
                action_show:false,
                showSearchDialog:false,
                columns:[],
                user:'',
                scrapUser:'',
                serarchModel:{
                    name:'',
                    licensePlate:'',
                    start_date:'',
                    end_date:'',
                    orderStatus:'',
                    orderStatusText:'',
                },
                go_url:'',
                pageindex:1,
                tableData:[],
                historyTableData:[],
                keys:[],
                price_show:true,
                price_history_show:true,
            }
        },
        methods:{
            cancelUrl(){
                this.$router.go(-1);
                // if(this.go_url){
                //     this.$router.replace({path:this.go_url,query:{active:2}})
                // }else
                // {
                //     this.$router.replace({path:'/mindex',query:{active:2}})
                // }
            },
            tabBeforChange(index){                
                if(index == 1){
                    this.price_show = false;
                    this.price_history_show = true;
                    this.initHistoryOrderTable();
                }else{
                    this.price_show = true;
                    this.price_history_show = false;
                    this.initTableData(this);
                }
                // 返回 Promise 来执行异步逻辑
                return new Promise((resolve) => {
                    // 在 resolve 函数中返回 true 或 false
                    resolve(true);
                });
            },     
            initHistoryOrderTable(that){
                that = that||this;
                if(that.isHostoryInit){                    
                    return '';
                }else{
                    that.isHostoryInit = true;
                }
                if(!this.isScrap){
                    var body = {
                        ProviderId:that.user.providerId
                    };
                    commissionList(body).then(res=>{
                        that.isHostoryInit = false;
                        that.historyTableData = res.content;
                        that.isLoading = false;
                    });
                }else{
                     var req = {
                        IsDesc:true,
                        PageSize:20,
                        PageIndex:this.pageindex,
                        ScrapUserId:this.user.userId,
                        HasTransaction:false
                    }
                    this.$toast.loading({duration: 0,forbidClick: true,message: '加载中',});
                    getSelfList(req).then(res=>{
                        this.$toast.clear();
                        that.isHostoryInit = false;
                        that.historyTableData = res.content;
                        that.isLoading = false;
                    });
                }
                
            },
            tagStyle(status){
                switch(status){
                    case 8:
                        return '#07c160';
                    case 7:
                        return '#c1c1c1';
                    default:
                        return '#1989fa';
                }
            },
            initTableData(that){
                if(that.isInit){                    
                    return '';
                }else{
                    that.isInit = true;
                }
                var body = {
                    IsDesc:true,
                    PageSize:10,
                    UserId:this.user.userId,
                    PageIndex:this.pageindex,
                    BillType:4
                }
                userBillList(body).then(res=>{
                    var data = res&&(res.content||[]);
                    var date;
                    data.forEach(e => {
                        date = new Date(e.createTime);
                        var flag = date.getFullYear()+'年'+(date.getMonth()+1)+'月';
                        if(!that.tableData[flag]){
                            this.keys.push(flag);
                            that.tableData[flag] = [];
                        }
                        that.tableData[flag].push(e);
                    });
                    this.price_show = false;
                    this.price_show = true;
                    if(data.length>0){
                        that.pageindex++;
                    }
                    that.isInit = false;
                    that.isLoading = false;
                });
            },
            getDocumentTop() {
                var scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
                if (document.body) {
                    bodyScrollTop = document.body.scrollTop;
                }
                if (document.documentElement) {
                    documentScrollTop = document.documentElement.scrollTop;
                }
                scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;    return scrollTop;
            },
            getWindowHeight() {
                var windowHeight = 0;
                if (document.compatMode == "CSS1Compat") {
                    windowHeight = document.documentElement.clientHeight;
                } else {
                    windowHeight = document.body.clientHeight;
                }
                return windowHeight;
            },
            getScrollHeight() {
                var scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
                if (document.body) {
                    bodyScrollHeight = document.body.scrollHeight;
                }
                if (document.documentElement) {
                    documentScrollHeight = document.documentElement.scrollHeight;
                }
                scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;    return scrollHeight;
            },
            onCancel(){
            },
            onConfirm(){
                this.pageindex = 1;
                this.tableData = [];
                this.initTableData(this);
            },
            onSelect(item){
                this.serarchModel.orderStatusText = item.name;
                this.serarchModel.orderStatus = item.id;
            },
            onRefresh(){
                this.isLoading = true;
                this.pageindex= 1;
                if(this.active == 0){
                    this.initTableData(this);
                }else{
                    this.initHistoryOrderTable(this);
                }
            },scrollEvent(that){
                if((that.getScrollHeight()-10) <= that.getWindowHeight() + that.getDocumentTop()){
                    if(that.active == 0){
                        that.initTableData(that);
                    }else{
                        that.initHistoryOrderTable(that);
                    }
                }
            }
        },
        beforeRouteLeave(to, form, next){
            window.removeEventListener('scroll',this.scrollEvent(this));
            next();
        },
        mounted(){
            this.tableData = [];
            this.historyTableData = [];
          
            var active = this.$route.query.active;
            if(active>0){
                this.active =parseInt(active);
                this.price_history_show = true;
                this.price_show = false;
                this.initHistoryOrderTable(this);
            }
            this.go_url = this.$route.query.go_url;
            this.initTableData(this);
            for(var i in this.$enum.userOrderStatus){
                // console.log(this.$enum.orderStatus[i]);
                this.actions.push({name:this.$enum.userOrderStatus[i],id:i});
            }
            window.addEventListener('scroll',this.scrollEvent(this));
        },
        created(){     
            this.user = allinpayUser.get();
            this.isScrap = this.user.userSource==2
        }
    })
</script>
<style scoped>
.top_btn_div{
    position: fixed;top: 0px;right: 0px;
}
.date_title{
    font-weight: 600;
    padding: 10px 10px;
    background-color: #c0c0c029;
}
.date_title label,.body_date{
    font-weight: 400;
    font-size: 14px;
    color: #8c8c8c;
}
.price_body{    
    padding: 10px 10px;
    border-bottom: 1px solid #ebedf0;
}
</style>