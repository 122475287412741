import axios from './request'

export function wxScrapUser(body){
  return axios({
      url: "/scrapUser/MPRegister",
      method: "post",
      data: body
  })
}
export function sendRegisterCodeForPerson(body){
  return axios({
      url: "/SMS/SendSMS",
      method: "get",
      params: body
  })
}
export function saveScrapIDCardPhoto(body){
  return axios({
      url: "/ScrapUser/SaveIDCardPhoto",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      params: body
  })
}
export function ScrapUpdateInfo(body){
  return axios({
      url: "/ScrapUser/UpdateInfo",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      data: body
  })
}
export function getSelfList(body){
  return axios({
      url: "/autoCollection/GetSelfList",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,   
      params: body
  })
}